import { createApp } from 'vue';
import Hammer from 'hammerjs';
import ThemeSwitcher from "@components/ThemeSwitcher.vue";

import '@styles/app.pcss';

const app = createApp({
    components: {
        ThemeSwitcher
    },
});

app.mount('#app');

var swipeImage = document.querySelector('.swipe-image');
if (swipeImage) {
    var hammer = new Hammer(swipeImage);

    hammer.on('swipeleft', () => {
        window.location.href = swipeImage.dataset.nextUrl;
    });

    hammer.on('swiperight', () => {
        window.location.href = swipeImage.dataset.prevUrl;
    });
}
